import React, { useContext } from "react";
import { DataContext, LanguageContext, PathContext } from "../App";

function Footer() {
    const language = useContext(LanguageContext);
    const pathState = useContext(PathContext);
    const data = useContext(DataContext);
    const SCROLL_OFFSET = 100;

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const elementPosition =
                element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - SCROLL_OFFSET;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
            pathState?.setPath(id);
        }
    };
    return (
        <footer className="relative flex flex-col items-center w-full py-4 space-y-4 md:px-16">
            <div className="absolute inset-0 z-0">
                <img
                    className="w-full max-sm:hidden h-full"
                    src="/images/footerBg.png"
                    alt="footer Background"
                />
                <img
                    className="w-full sm:hidden h-full"
                    src="/images/footerBgMobile.png"
                    alt="footer Background"
                />
            </div>
            <div className="flex max-md:flex-col relative max-sm:text-sm  pt-36 pb-16 z-10 md:justify-between gap-8 w-full items-center">
                <img src="/images/logo.png" alt="logo" className="w-28 sm:w-40" />
                <div className="flex max-md:hidden flex-col gap-4">
                    <span
                        onClick={() => scrollToSection("home")}
                        className="hover:underline cursor-pointer"
                    >
                        {language === "english" ? "Home" : "الرئيسية"}
                    </span>
                    <span
                        onClick={() => scrollToSection("sections")}
                        className="hover:underline cursor-pointer"
                    >
                        {language === "english" ? "Sections" : "الأقسام"}
                    </span>
                    <span
                        onClick={() => scrollToSection("courses")}
                        className="hover:underline cursor-pointer"
                    >
                        {language === "english" ? "Courses" : "الدورات"}
                    </span>
                </div>
                <div className="flex max-md:hidden flex-col gap-4">
                    <span
                        onClick={() => scrollToSection("instructors")}
                        className="hover:underline cursor-pointer"
                    >
                        {language === "english" ? "Instructors" : "المدربين"}
                    </span>
                    <span
                        onClick={() => scrollToSection("overview")}
                        className="hover:underline cursor-pointer"
                    >
                        {language === "english" ? "Overview" : "لمحة عامة"}
                    </span>
                </div>
                <div className="flex items-center gap-20 md:hidden">
                    <div className="flex flex-col gap-2">
                        <span
                            onClick={() => scrollToSection("home")}
                            className="hover:underline cursor-pointer"
                        >
                            {language === "english" ? "Home" : "الرئيسية"}
                        </span>
                        <span
                            onClick={() => scrollToSection("sections")}
                            className="hover:underline cursor-pointer"
                        >
                            {language === "english" ? "Sections" : "الأقسام"}
                        </span>
                        <span
                            onClick={() => scrollToSection("courses")}
                            className="hover:underline cursor-pointer"
                        >
                            {language === "english" ? "Courses" : "الدورات"}
                        </span>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span
                            onClick={() => scrollToSection("instrucors")}
                            className="hover:underline cursor-pointer"
                        >
                            {language === "english" ? "Instructors" : "المدربين"}
                        </span>
                        <span
                            onClick={() => scrollToSection("overview")}
                            className="hover:underline cursor-pointer"
                        >
                            {language === "english" ? "Overview" : "لمحة عامة"}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col justify-center max-md:items-center gap-4">
                    <p>{language === "english" ? "Contact Us" : "تواصل معنا"}</p>
                    <p>{data.footer.email}</p>
                    <p>{data.footer.phone}</p>
                </div>
            </div>
            <p className="text-xs text-center relative z-10 font-light">
                Copyright @ 2023 .All Right Reservedm Developed and desigend by{" "}
                <span onClick={() => scrollToSection("")} className="font-bold">
                    ICR
                </span>
            </p>
        </footer>
    );
}

export default Footer;
