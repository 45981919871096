import React, { useContext } from "react";
import { GooglePlay } from "./ui/svgs";
import { DataContext, LanguageContext } from "../App";
import { Button } from "./ui/button";

function Download() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);

  return (
    <div className=" md:py-16">
      <div className="flex md:gap-12 justify-center items-center w-full bg-[#146ED5] md:h-40 relative py-32 px-4 xl:px-12 text-white ">
        <div>
          <img
            className="relative max-md:hidden w-[270px] z-10"
            src="/images/phone.png"
            alt="phone"
          />
        </div>

        <div className="flex max-md:flex-col items-center gap-12">
          <p className="text-2xl md:text-3xl max-md:text-center md:max-w-sm lg:max-w-md relative z-0 font-semibold">
          {data.application.description}
          </p>
          <img
            className="w-[270px] md:hidden"
            src="/images/phone.png"
            alt="phone"
          />
          <div className="space-y-4 max-md:w-full text-center max-md:w-72 relative z-0">
            <span className="font-semibold text-xl">
              {language === "english" ? "Download from here" : "للتحميل من هنا"}
            </span>
            <a href={data.application.google_play} size="lg" className="flex w-full gplay-font px-5 py-2.5 rounded-md justify-center items-center gap-3 bg-white hover:bg-gray-200 text-black">
              <div className="w-8 h-8">
                <GooglePlay />
              </div>
              <div className="flex flex-col">
                <p className="font-extrabold text-xs">Android App On</p>
                <p className="font-extrabold text-lg">Google Play</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
