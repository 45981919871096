import React, { createContext, useEffect, useRef, useState } from "react";
import Accomplishments from "./components/Accomplishments";
import ContactUs from "./components/ContactUs";
import Download from "./components/Download";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Instructors from "./components/Instructors";
import OurCourses from "./components/OurCourses";
import Overview from "./components/Overview";
import Sections from "./components/Sections";
import "./App.css";
import { HalfCircle } from "./components/ui/svgs";
import { useFETCH } from "./Tools/APIs/index"


export const LanguageContext = createContext("عربي");
export const PathContext = createContext(undefined); 
export const DataContext = createContext(undefined);

export default function Page() {
  const [language, setLanguage] = useState("عربي");
  const [path, setPath] = useState("home");

  const {data} = useFETCH(`home`);
  const dataAll = data?.data;
  console.log(data);

  const sectionRefs = {
    home: useRef(null),
    sections: useRef(null),
    courses: useRef(null),
    overview: useRef(null),
    instructors: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      const offsets = (Object.keys(sectionRefs)).map(key => {
        const element = sectionRefs[key].current;
        //@ts-ignore
        return element.getBoundingClientRect().top + window.scrollY;
      });

      const scrollPosition = window.scrollY + window.innerHeight / 2;

      const active = Object.keys(sectionRefs).find((key, index) => {
        return (
          scrollPosition >= offsets[index] &&
          (index === Object.keys(sectionRefs).length - 1 || scrollPosition < offsets[index + 1])
        );
      });

      if (active) setPath(active);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);

  if(!data)
    return <div>loading</div>

  return (
    <div
      dir={language === "english" ? "ltr" : "rtl"}
      className={`text-white space-y-4 md:space-y-8 ${language === "عربي" ? "font-family" : ""}`}
    >
      <DataContext.Provider value={dataAll}>
      <LanguageContext.Provider value={language}>
        <PathContext.Provider value={{ setPath, path }}>
          <div ref={sectionRefs.home} id="home" className="relative w-full h-screen pb-12">
            <div className="absolute h-full w-full top-0 left-0 z-10">
              <div className={`absolute ${language === "english" ? "left-0" : "left-0"} max-md:w-[80px] max-md:h-[80px] bottom-36`}>
                <HalfCircle />
              </div>
              <img className="w-full h-full max-md:hidden" src="/images/heroBg.png" alt="Hero Background" />
              <img className="w-full h-full md:hidden" src="/images/heroBgMobile.png" alt="Hero Background" />
            </div>
            <div className="px-2 md:px-4 h-full lg:px-10">
              <Header setLanguage={setLanguage} />
              <Hero />
              <Accomplishments />
            </div>
          </div>
          <div ref={sectionRefs.sections} id="sections">
            <Sections />
          </div>
          <div ref={sectionRefs.courses} id="courses">
            <OurCourses />
          </div>
          <div ref={sectionRefs.overview} id="overview">
            <Overview />
          </div>
          <div ref={sectionRefs.instructors} id="instructors" className="flex items-center justify-center w-full">
            <Instructors />
          </div>
          <Download />
          <ContactUs />
          <Footer />
        </PathContext.Provider>
      </LanguageContext.Provider>
      </DataContext.Provider>
    </div>
  );
}
