import React, { useContext } from "react";
import { LanguageContext, PathContext } from "../App";
import { Button } from "./ui/button";

function NavBar() {
  const pathState = useContext(PathContext);
  const language = useContext(LanguageContext);
  const SCROLL_OFFSET = 100;

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - SCROLL_OFFSET;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      pathState?.setPath(id);
    }
  };
  
  return (
    <div className="flex justify-center gap-2 lg:gap-12 items-center">
      <Button
        className={`${
          pathState?.path === "home" ? "bg-orange-500" : ""
        } hover:bg-orange-500 hover:text-white`}
        variant={"ghost"}
        onClick={() => scrollToSection("home")}
      >
        {language !== "عربي" ? "Home" : "الرئيسية"}
      </Button>

      <Button
        className={`${
          pathState?.path === "sections" ? "bg-orange-500" : ""
        } hover:bg-orange-500 hover:text-white`}
        variant={"ghost"}
        onClick={() => scrollToSection("sections")}
      >
        {language !== "عربي" ? "Sections" : "الأقسام"}
      </Button>

      <Button
        className={`${
          pathState?.path === "courses" ? "bg-orange-500" : ""
        } hover:bg-orange-500 hover:text-white`}
        variant={"ghost"}
        onClick={() => scrollToSection("courses")}
      >
        {language !== "عربي" ? "Courses" : "الدورات"}
      </Button>

      <Button
        className={`${
          pathState?.path === "overview" ? "bg-orange-500" : ""
        } hover:bg-orange-500 hover:text-white`}
        variant={"ghost"}
        onClick={() => scrollToSection("overview")}
      >
        {language !== "عربي" ? "Overview" : "لمحة عامة"}
      </Button>

      <Button
        className={`${
          pathState?.path === "instructors" ? "bg-orange-500" : ""
        } hover:bg-orange-500 hover:text-white`}
        variant={"ghost"}
        onClick={() => scrollToSection("instructors")}
      >
        {language !== "عربي" ? "Instructors" : "المشرفون"}
      </Button>
    </div>
  );
}

export default NavBar;
