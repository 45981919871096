import React, { useState, useEffect } from "react";
import { Globe } from "./ui/svgs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "./ui/select";
import NavDrawer from "./NavDrawer";
import NavBar from "./NavBar";

function Header({ setLanguage }) {
  const [isHeaderFixed, setIsHeaderFixed] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className={`relative w-full z-30`}>
      <div
        className={`flex px-2 md:px-4 bg-gradient-to-b from-[#127EE1] to-[#1550BD] lg:px-10 fixed left-0 right-0 w-full justify-between pt-2 items-center`}
      >
        <div className="">
          <img src="/images/logo.png" alt="logo" className="w-28 md:w-36" />
        </div>

        <div className="flex items-center gap-2 lg:gap-4 max-md:hidden ">
          <NavBar />
          <LangaugeSelect setLanguage={setLanguage} />
        </div>

        <div className="flex items-center gap-2 lg:gap-4 md:hidden ">
          <NavDrawer />
          <LangaugeSelect setLanguage={setLanguage} />
        </div>
      </div>
    </div>
  );
}

function LangaugeSelect({ setLanguage }) {
  return (
    <Select onValueChange={(value) => {setLanguage(value); window.location.reload();}}>
      <SelectTrigger className="text-xs p-1 text-white bg-orange-500 space-x-1 border-0 outline-0">
        <Globe />
      </SelectTrigger>
      <SelectContent className="text-sm">
        <SelectItem value="english">English</SelectItem>
        <SelectItem value="عربي">عربي</SelectItem>
      </SelectContent>
    </Select>
  );
}

export default Header;