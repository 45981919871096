import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LanguageContext, DataContext } from "../App";
import { fileUrl, useFETCH } from "../Tools/APIs";
import axios from "axios";

function Carousel() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);
  const mediaQuery = window.matchMedia("(min-width: 768px)");
  let s = 1;
  if (language === "english") {
    if (mediaQuery.matches) {
      s = 1;
    } else {
      s = 0;
    }
  } else {
    if (mediaQuery.matches) {
      s = 1;
    } else {
      s = 0;
    }
  }
  const [slide, setSlide] = useState(s);
  const [previousSlide, setPreviousSlide] = useState(s);
  const sections = data.sections.data;
   
  const settings = {
    className: "center",
    dots: true,
    arrows: false,
    slidesToShow: 3,
    speed: 500,
    initialSlide: language === "english" ? 0 : sections.length - 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    rtl: language === "english" ? false : true,
    beforeChange: (currentSlide, nextSlide) => {
      if (nextSlide !== sections.length - 1)
        setSlide((previousValue) => {
          setPreviousSlide(previousValue);
          return nextSlide + 1;
        });
      else
        setSlide((previousValue) => {
          setPreviousSlide(previousValue);
          return 0;
        });
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          initialSlide: language === "english" ? 0 : sections.length - 1,
          centerMode: true,
          centerPadding: "25%",
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          beforeChange: (currentSlide, nextSlide) => {
            setSlide((previousValue) => {
              setPreviousSlide(previousValue);
              return nextSlide;
            });
          },
        },
      },
    ],
  };

  return (
    <div className="slider-container lg:px-8">
      <Slider {...settings}>
        {sections.map((section, index) => 
          <CarouselItem
            text={section.description}
            title={section.name}
            image={fileUrl + section.image}
            id={index}
            slide={slide}
            setSlide={setSlide}
            previousSlide={previousSlide}
            setPreviousSlide={setPreviousSlide}
          />
        )}
      </Slider>
    </div>
  );
}

function CarouselItem({
  image,
  title,
  text,
  id,
  slide,
  setSlide,
  previousSlide,
  setPreviousSlide,
}) {
  const language = useContext(LanguageContext);
  return (
    <div className="h-[450px] md:h-[500px] cursor-pointer">
      <div
        id={id.toString()}
        onMouseOver={(e) => {
          const mediaQuery = window.matchMedia("(min-width: 768px)");
          if (id !== slide && mediaQuery.matches) {
            setSlide((previousSlide) => {
              setPreviousSlide(previousSlide);
              return 3;
            });
            e.currentTarget.classList.add("slick-selected");
          }
        }}
        onMouseOut={(e) => {
          const mediaQuery = window.matchMedia("(min-width: 768px)");
          if (id !== slide && mediaQuery.matches) {
            setSlide(previousSlide);
            e.currentTarget.classList.remove("slick-selected");
          }
        }}
        className={`scale-90 relative z-0 flex flex-col items-center gap-2 md:gap-8 ${
          id === slide ? "slick-selected -ml-6 -mr-6 sm:-ml-8 sm:-mr-8 " : ""
        } h-full text-[#154FBC] overflow-clip p-4 lg:p-6 h-full rounded-2xl border-[#154FBC] border-4 relative transition-all`}
      >
        <div className="flex flex-col items-center gap-2">
          <img src={image} alt="brain" className="w-[300px]" />
          <p
            dir={language == "english" ? "ltr" : "rtl"}
            className="text-lg md:text-3xl font-bold"
          >
            {title}
          </p>
        </div>

        <p
          dir={language == "english" ? "ltr" : "rtl"}
          className="max-sm:text-sm"
        >
          {text}
        </p>
      </div>
    </div>
  );
}

export default Carousel;
