import React, { useContext, useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer";
import { Menu } from "./ui/svgs";
import { LanguageContext, PathContext } from "../App";
import { Button } from "./ui/button";

function NavDrawer() {
  const pathState = useContext(PathContext);
  const language = useContext(LanguageContext);

  const SCROLL_OFFSET = 60;
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - SCROLL_OFFSET;
        pathState?.setPath(id);
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 350);
    }
  };

  return (
    <div>
      <Drawer direction={language === "english" ? "right" : "left"}>
        <DrawerTrigger className="flex items-center">
          <Menu />
        </DrawerTrigger>
        <DrawerContent
          className={`h-full w-[75%] after:hidden ${
            language === "english" ? "" : "inset-x-0"
          } bg-gradient-to-b from-[#127EE1] to-[#163EAF] border-0`}
        >
          <DrawerHeader>
            <DrawerTitle>
              <img
                src="/images/logo.png"
                alt="logo"
                className="w-28 md:w-36 mx-auto"
              />
            </DrawerTitle>
          </DrawerHeader>
          <div className="flex text-white flex-col items-center gap-4">
            <DrawerClose className="w-full">
              <Button
                className={`w-full ${
                  pathState?.path == "home" ? "bg-orange-500" : ""
                } hover:bg-orange-500 hover:text-white`}
                variant={"ghost"}
                size="lg"
                onClick={() => scrollToSection("home")}
              >
                {language !== "عربي" ? "Home" : "الرئيسية"}
              </Button>
            </DrawerClose>
            <DrawerClose className="w-full">
              <Button
                className={`w-full ${
                  pathState?.path == "sections" ? "bg-orange-500" : ""
                } hover:bg-orange-500 hover:text-white`}
                variant={"ghost"}
                onClick={() => scrollToSection("sections")}
                size="lg"
              >
                {language !== "عربي" ? "Sections" : "الأقسام"}
              </Button>
            </DrawerClose>
            <DrawerClose className="w-full">
              <Button
                className={`w-full ${
                  pathState?.path == "courses" ? "bg-orange-500" : ""
                } hover:bg-orange-500 hover:text-white`}
                variant={"ghost"}
                size="lg"
                onClick={() => scrollToSection("courses")}
              >
                {language !== "عربي" ? "Courses" : "الدورات"}
              </Button>
            </DrawerClose>
            <DrawerClose className="w-full">
              <Button
                className={`w-full ${
                  pathState?.path == "overview" ? "bg-orange-500" : ""
                } hover:bg-orange-500 hover:text-white`}
                variant={"ghost"}
                size="lg"
                onClick={() => scrollToSection("overview")}
              >
                {language !== "عربي" ? "Overview" : "لمحة عامة"}
              </Button>
            </DrawerClose>
            <DrawerClose className="w-full">
              <Button
                className={`w-full ${
                  pathState?.path == "instructors" ? "bg-orange-500" : ""
                } hover:bg-orange-500 hover:text-white`}
                variant={"ghost"}
                size="lg"
                onClick={() => scrollToSection("instructors")}
              >
                {language !== "عربي" ? "Instructors" : "المشرفون"}
              </Button>
            </DrawerClose>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default NavDrawer;
