import React, { useContext } from "react";
import {
  Degree,
  InstructorsSvg,
  OverviewImages,
  ShortCourses,
} from "./ui/svgs";
import { DataContext, LanguageContext } from "../App";

function Overview() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);

  return (
    <div className="flex flex-col-reverse md:flex-row w-full px-2 md:px-10 py-8  justify-center items-center bg-gradient-to-b from-[#117CE0] to-[#1843B3] text-white gap-5">
      <div className="basis-1/2 max-md:w-full p-4 md:h-full flex  lg:scale-75">
        <OverviewImages />
      </div>
      <div className="basis-1/2 md:w-1/2 flex flex-col gap-8 justify-center">
        <h4 className="text-2xl md:text-3xl lg:text-4xl lg:text-5xl max-md:self-center mb-4 font-bold">
          لمحة عامة
        </h4>
        <div className="flex gap-6 md:gap-8">
          <div className="rounded-full flex items-center w-fit h-fit bg-white p-2">
            <Degree />
          </div>
          <div className="space-y-2">
            <h6 className="md:text-xl font-semibold">الشهادات</h6>
            <p className="max-sm:text-sm">{data.overview.online_degrees}</p>
          </div>
        </div>
        <div className="flex gap-6 md:gap-8">
          <div className="rounded-full flex items-center w-fit h-fit bg-white p-2">
            <ShortCourses />
          </div>
          <div className="space-y-2">
            <h6 className="md:text-xl font-semibold">دورات قصيرة</h6>
            <p className="max-sm:text-sm">{data.overview.short_courses}</p>
          </div>
        </div>
        <div className="flex gap-6 md:gap-8">
          <div className="rounded-full flex items-center w-fit h-fit bg-white p-2">
            <InstructorsSvg />
          </div>
          <div className="space-y-2">
            <h6 className="md:text-xl font-semibold">مدربين محترفين</h6>
            <p className="max-sm:text-sm">{data.overview.professional_instructors}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
