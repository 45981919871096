import React, { useContext } from "react";
import { DataContext } from "../App";

function Hero() {
  const data = useContext(DataContext);

  return (
    <div className="flex h-[90%] relative pt-20 z-20 px-4 md:px-12 max-md:flex-col-reverse justify-center gap-4 items-center ">
        <img
          className=" md:w-96 max-md:h-1/2"
          src="/images/hero.png"
          alt="hero image"
        />
      <div className="text-center p-2 sm:p-4 max-sm:pt-0 space-y-4">
        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold lg:leading-normal">
          {
           "الطريقة الأفضل للتعلم"
          }
          
        </h2>
        <p className="max-sm:text-sm">
          {
            data.hero.description
          }
        </p>
      </div>
    </div>
  );
}

export default Hero;
