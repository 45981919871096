import React, { useContext } from "react";
import { DataContext, LanguageContext } from "../App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fileUrl } from "../Tools/APIs";

export default function Intstructors() {
  const language = useContext(LanguageContext);
  const data = useContext(DataContext);
  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    rtl: language === "english" ? false : true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="slider-container w-full md:w-2/3 py-4 px-2 md:px-8 lg:px-12 text-black">
      <Slider className="center" {...settings}>
        {data.instructors.data.map((instructor) => (
          <SliderItem
            image={fileUrl + instructor.image}
            name={instructor.first_name + " " + instructor.last_name}
            description={instructor.description}
          />
        ))}
      </Slider>
    </div>
  );
}

function SliderItem({ image, name, description }) {
  return (
    <div className="flex flex-col items-center p-4 md:p-12 gap-12">
      <div className="space-y-8">
        <h6 className="text-center text-[#154FBC] text-2xl md:text-4xl lg:text-5xl font-bold">
          {name}
        </h6>
        <p className="max-md:text-sm">{description}</p>
      </div>
      <img
        src={image}
        alt={name}
        className="w-60 h-60 md:w-80 md:h-80 rounded-full"
      />
    </div>
  );
}
